<script lang="ts" context="module">
  type TextElement = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';

  const TextSizes = {
    display: ['152', '128', '104', '68', '52'],
    heading: ['68', '60', '48', '32', '24'],
    body: ['32', '24', '16'],
    eyebrow: ['18', '16'],
    mono: ['18', '16'],
  } as const;

  type TextVariant = keyof {
    [K in keyof typeof TextSizes as `${K}-${(typeof TextSizes)[K][number]}`]: never;
  };
</script>

<script lang="ts">
  type $$Props = {
    as?: TextElement;
    variant: TextVariant;
    class?: string;
    entityId?: string;
    fieldId?: string;
  };

  export let as: TextElement = 'p';

  export let entityId: string | undefined = undefined;
  export let fieldId: string | undefined = undefined;

  let className = '';
  export { className as class };

  let textVariant = '';
  export { textVariant as variant };

  $: [variant, size] = textVariant.split('-');
</script>

<svelte:element
  this={as}
  data-variant={variant}
  data-size={size}
  class="text {variant} s{size} {className}"
  data-contentful-entry-id={entityId}
  data-contentful-field-id={fieldId}
>
  <slot />
</svelte:element>

<style lang="postcss">
  .text {
    &.s152 {
      @apply max-lg:text-[76px] lg:text-[114px] xl:text-[152px] lg:tracking-[-2px];
    }
    &.s128 {
      @apply max-md:text-[48px] max-lg:text-[64px] lg:text-[96px] xl:text-[128px] lg:tracking-[-2px];
    }
    &.s104 {
      @apply max-lg:text-[48px] lg:text-[78px] xl:text-[104px] lg:tracking-[-2px];
    }
    &.s68 {
      @apply max-md:text-[44px] max-lg:text-[52px] lg:text-[60px] xl:text-[68px] lg:tracking-[-2px];
    }
    &.s60 {
      @apply max-md:text-[36px] md:text-[44px] lg:text-[52px] xl:text-[60px];
    }
    &.s52 {
      @apply text-[52px];
    }
    &.s48 {
      @apply max-xl:text-[32px] xl:text-[48px];
    }
    &.s32 {
      @apply max-xl:text-[24px] xl:text-[32px];
    }
    &.s24 {
      @apply max-xl:text-[18px] xl:text-[24px];
    }
    &.s18 {
      @apply max-md:text-[12px] md:text-[18px];
    }
    &.s16 {
      @apply max-md:text-[12px] md:text-[14px] xl:text-[16px];
    }
  }

  .display {
    @apply font-sans max-md:tracking-normal font-light md:tracking-[-2px] leading-[110%];
  }
  .heading {
    @apply font-sans max-lg:tracking-normal leading-[120%];
  }

  .body {
    @apply font-sans font-light leading-[140%];
  }

  .eyebrow,
  .mono {
    @apply font-mono font-medium tracking-tight;

    &.s16 {
      @apply uppercase;
    }
  }
</style>
